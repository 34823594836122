export const FirebaseConfig = {
	"projectId": "ije-rides-73c97",
	"appId": "1:1056838846100:web:92d3a5f678007586399681",
	"databaseURL": "https://ije-rides-73c97-default-rtdb.firebaseio.com",
	"storageBucket": "ije-rides-73c97.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyD1bzxBA45VzlSXZyfoRQfqluwWGYC1Azc",
	"authDomain": "ije-rides-73c97.firebaseapp.com",
	"messagingSenderId": "1056838846100",
	"measurementId": "G-DT6VEWCQHH"
};