export const colors = {
  LandingPage_Background: "#FFFFFF",
  LandingPage_Description: "#373737",
  Button_Primary: "#46F34D",
  Button_Secondary: "#ed6c03",
  Header_Background: "#FFFFFF",
  Header_Content: "#000000",
  Black: "#000000",
  Header: "#383C3D",
  Header_Text: "#FFFFFF",
  Header_Text_back: "#4C5152",
  Table_Header: "#BFCACD",
  Action_Button_Back: "#0A6AAD",
  ALERT_BUTTON_GOTO_COLOR: "#c6e2f6",
  ALERT_BUTTON_CLOSE_COLOR: "#ea969d",
  HEADER: "#ed6c03",
  BORDER_BACKGROUND: "#d6d6d6",
  CAR_BOX_EVEN: "#DEDFD5",
  CAR_BOX_ODD: "#ed6c03",
};
